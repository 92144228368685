import React, { createContext, useReducer } from 'react'

const SIGN_IN = 'SIGN_IN'
const SIGN_OUT = 'SIGN_OUT'
const POST_TYPE = 'POST_BLOCK'

const initState = {
  user: null,
  isLoggedIn: false,
  postType: null,
}

export const AuthContext = createContext()

export const signInAction = (user) => {
  return {
    type: SIGN_IN,
    payload: user,
  }
}

export const signOutAction = () => {
  return {
    type: SIGN_OUT,
  }
}

export const postTypeAction = (postType) => {
  return {
    type: POST_TYPE,
    postType: postType,
  }
}

const reducer = (state, action) => {
  switch (action.type) {
    case SIGN_IN:
      return {
        ...state,
        user: action.payload,
        isLoggedIn: !!action.payload,
      }
    case SIGN_OUT:
      return {
        ...state,
        user: null,
        isLoggedIn: false,
      }
    case POST_TYPE:
      return {
        ...state,
        postType: action.postType,
      }
    default:
      return state
  }
}

const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initState)

  return (
    <AuthContext.Provider value={{ state, dispatch }}>
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
