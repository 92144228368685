/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import { css, Global } from '@emotion/react'

const styles = css`
  :root {
    --primary-color: #ea8d22;
    --secondary-color: #0f151b;
    --white-color: #f9f9f9;
    --black-color: #000000;
    --background-color: #11161c;
    --primary-color-rgb: 235, 142, 33;
    --secondary-color-rgb: 15, 21, 27;
    --white-color-rgb: 255, 255, 255;
    --black-color-rgb: 0, 0, 0;
    --background-color-rgb: 17, 22, 28;
    --primary-font: 'Manrope', sans-serif;
    --secondary-font: 'Roboto', sans-serif;
  }

  html,
  body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
  }

  body {
    background-color: white;
    font-family: var(--primary-font);
    /* -ms-overflow-style: none; Hide scrollbar IE and Edge */
    /* scrollbar-width: none; Hide scrollbar Firefox */
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  /* body::-webkit-scrollbar {
    display: none;
  } */

  a,
  a:hover,
  a:focus,
  a:active {
    text-decoration: none;
    color: inherit;
  }
`

const GlobalLayout = ({ children }) => {
  return (
    <>
      <Global styles={styles} />
      {children}
    </>
  )
}

GlobalLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default GlobalLayout
