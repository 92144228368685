import React, { createContext, useState } from 'react'
import styled from '@emotion/styled'
import { BLACK_COLOR_RGB } from '../components/common/color'

const Loading = styled.div`
  display: ${(props) => (props.isloading ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${BLACK_COLOR_RGB(0.3)};
  z-index: 9999;
`

export const LoadingContext = createContext()
const LoadingProvider = ({ children }) => {
  const [loading, setLoading] = useState(false)

  return (
    <LoadingContext.Provider value={{ loading, setLoading }}>
      <Loading isloading={loading} />
      {children}
    </LoadingContext.Provider>
  )
}

export default LoadingProvider
