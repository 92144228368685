import React from 'react'
import GlobalLayout from './src/components/layouts/global'
import AuthProvider from './src/context/auth-context'
import LoadingProvider from './src/context/loading-context'
import NavProvider from './src/context/nav-context'

export const wrapPageElement = ({ element, props }) => {
  return <GlobalLayout {...props}>{element}</GlobalLayout>
}

export const wrapRootElement = ({ element }) => {
  return (
    <LoadingProvider>
      <AuthProvider>
        <NavProvider>{element}</NavProvider>
      </AuthProvider>
    </LoadingProvider>
  )
}
