export const PRIMARY_COLOR = 'var(--primary-color)'
export const SECONDARY_COLOR = 'var(--secondary-color)'
export const WHITE_COLOR = 'var(--white-color)'
export const BLACK_COLOR = 'var(--black-color)'
export const BLUE_COLOR = 'var(--blue-color)'
export const BACKGROUND_COLOR = 'var(--background-color)'
export const GREY_COLOR = 'rgb(228,228,228)'

export const PRIMARY_COLOR_RGB = (opacity = 1) =>
  `rgba(var(--primary-color-rgb), ${opacity})`
export const SECONDARY_COLOR_RGB = (opacity = 1) =>
  `rgba(var(--secondary-color-rgb), ${opacity})`
export const WHITE_COLOR_RGB = (opacity = 1) =>
  `rgba(var(--white-color-rgb), ${opacity})`
export const BLACK_COLOR_RGB = (opacity = 1) =>
  `rgba(var(--black-color-rgb), ${opacity})`
export const BACKGROUND_COLOR_RGB = (opacity = 1) =>
  `rgba(var(--background-color-rgb), ${opacity})`
