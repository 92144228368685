import React, { createContext, useState } from 'react'

export const NavContext = createContext()

function NavProvider(props) {
  const [toggleState, setToggleState] = useState(0)
  const [isMenuOpen, setMenuOpen] = useState(false)
  console.log(toggleState)

  const toggleTab = (index) => {
    setToggleState(index)
    setMenuOpen(false)
  }

  return (
    <NavContext.Provider
      value={{ toggleState, toggleTab, isMenuOpen, setMenuOpen }}
    >
      {props.children}
    </NavContext.Provider>
  )
}

export default NavProvider
